import { Typography } from '@material-ui/core';
import React from 'react';

const NotFound = () => {
    return (
        <div align='center'>
            <Typography variant="h1">404</Typography>
            <Typography variant="h2">Page not found</Typography>

        </div >
    );
};

export default NotFound;